import {
  Box,
  Button,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Flexbetween from "components/FlexBetween";
import { formulaireSasu1 } from "constants/form.constants";
import { Call } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "state";

const NavigationBar = ({ isStepEnable }) => {
  const largeScreen = useMediaQuery("(min-width: 800px)");
  const theme = useTheme();
  const page = useSelector((state) => state.page);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setPage({ page: newValue + 1 }));
  };

  if (largeScreen) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        backgroundColor={theme.palette.background.alt}
        mt="1px"
      >
        <Tabs
          variant="scrollable"
          value={page - 1}
          onChange={handleChange}
          aria-label="formulaire tabs"
        >
          {formulaireSasu1.map((value, index) => {
            return (
              <Tab
                key={index}
                disabled={!isStepEnable(index)}
                sx={{
                  fontWeight: 700,
                }}
                label={value.label}
                icon={
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor:
                        index + 1 === page
                          ? theme.palette.primary.extra
                          : theme.palette.neutral.medium,
                      color: "#FFF",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index + 1}
                  </Box>
                }
                iconPosition="start"
              />
            );
          })}
        </Tabs>
      </Box>
    );
  } else {
    return (
      <>
        <LinearProgress variant="determinate" value={(100 * page) / 11} />
        <Flexbetween
          sx={{ p: "20px", backgroundColor: theme.palette.background.default }}
        >
          <Flexbetween gap="4px">
            <Typography
              sx={{
                backgroundColor: theme.palette.neutral.light,
                borderRadius: "10px",
                p: "5px",
              }}
            >
              {page}/11
            </Typography>
            <Typography
              sx={{
                backgroundColor: theme.palette.neutral.light,
                borderRadius: "10px",
                p: "5px",
              }}
            >
              {formulaireSasu1[page - 1].label}
            </Typography>
          </Flexbetween>
          <Button
            variant="contained"
            startIcon={<Call sx={{ fontSize: "25px" }} />}
          >
            061256348967
          </Button>
        </Flexbetween>
      </>
    );
  }
};

export default NavigationBar;
