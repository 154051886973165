import React from "react";
import {
  Box,
  useTheme,
  Divider,
  Zoom,
  Fab,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import Navbar from "scenes/navbar";
import EmptyDialog from "components/emptyDialog";
import Flexbetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CheckoutSuccess = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery("(min-width: 800px)");
  const ref = useSelector((state) => state.ref);
  const datas = useSelector((state) => state.datas);

  const downloadReport = async () => {

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/report/download?ref=${ref}`
      );
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.download = `statut-constitutif-${datas["societeName"]}.docx`;

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      toast.success("Téléchargement du rapport en cours...");
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  };

  const videoCard = () => {
    if (largeScreen) {
      return (
        <Box
          sx={{
            borderRadius: "16px",
            aspectRatio: "0.8",
            height: "80%",
            maxHeight: "400px",
            overflow: "hidden",
            position: "sticky",
            top: "220px",
          }}
        >
          <video
            style={{ objectFit: "cover", objectPosition: "center" }}
            width="100%"
            height="100%"
            controls
            src={
              "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
            }
          ></video>
        </Box>
      );
    } else {
      return (
        <Box sx={{ position: "fixed", bottom: 10, right: 5, opacity: 0.6 }}>
          <EmptyDialog
            content={
              <Box
                sx={{
                  borderRadius: "16px",
                  aspectRatio: "0.8",
                  height: "80%",
                  maxHeight: "400px",
                  overflow: "hidden",
                }}
              >
                <video
                  style={{ objectFit: "cover", objectPosition: "center" }}
                  width="100%"
                  height="100%"
                  controls
                  src={
                    "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
                  }
                ></video>
              </Box>
            }
          >
            <Zoom
              key="inherit"
              in={true}
              timeout={100}
              style={{
                transitionDelay: "100ms",
              }}
              unmountOnExit
            >
              <Fab
                sx={{
                  color: "common.white",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
                aria-label="Expand"
                color="inherit"
              >
                Video
              </Fab>
            </Zoom>
          </EmptyDialog>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
      >
        <Navbar />
      </Box>
      <Divider />
      <Flexbetween
        sx={{
          justifyContent: "center",
          gap: "4rem",
          alignItems: "flex-start",
          minHeight: "calc(100% - 120px)",
        }}
      >
        {videoCard()}
        <Box
          sx={{
            width: largeScreen ? "40%" : "100%",
            padding: "20px",
            mt: "50px",
          }}
        >
          <Flexbetween
            sx={{ flexDirection: "column", alignItems: "flex-start", gap: 4 }}
          >
            <Typography variant="h2" fontWeight="600">
              Félicitations, votre dossier est enregistré !
            </Typography>
            <Typography>
              Nous avons bien reçu vos informations. La création de votre
              société est en cours. Vous recevrez un email de confirmation avec
              les prochaines étapes sous peu.
            </Typography>
            <Box
              sx={{
                border: "1px solid #A3A3A3",
                borderRadius: "5px",
                p: "10px",
              }}
            >
              <Typography variant="h5" fontWeight="600">
                Que se passe-t-il ensuite ?
              </Typography>
              <ul>
                <li style={{ color: theme.palette.neutral.main }}>
                  <Typography sx={{ color: theme.palette.neutral.main }}>
                    Vérification des informations : Nous nous assurons que
                    toutes les données fournies sont complètes et conformes.
                  </Typography>
                </li>
                <li style={{ color: theme.palette.neutral.main }}>
                  <Typography sx={{ color: theme.palette.neutral.main }}>
                    Préparation des documents juridiques : Nos experts préparent
                    vos statuts, formulaires, et autres documents necessaires.
                  </Typography>
                </li>
                <li style={{ color: theme.palette.neutral.main }}>
                  <Typography sx={{ color: theme.palette.neutral.main }}>
                    Soumission au greffe : Nous déposons votre dossier auprés du
                    greffe compétent pour immatriculer votre société.
                  </Typography>
                </li>
                <li style={{ color: theme.palette.neutral.main }}>
                  <Typography sx={{ color: theme.palette.neutral.main }}>
                    Réception de l'extrait Kbis : Une fois la société
                    immatriculée, vous recevrez votre extrait Kbis par email.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Typography variant="h6" fontWeight="600">
              Si vous avez des questions ou des préoccupations, notre équipe est
              là pour vous aider. N'hésitez pas à nous contacter à{" "}
              <a href="mailto:contact@alceeavocats.com">
                contact@alceeavocats.com
              </a>{" "}
              ou au 0612345678
            </Typography>
            <Flexbetween gap={"20px"} flexWrap={"wrap"}>
              <Button variant="contained" onClick={() => downloadReport()}>
                Télécharger le rapport
              </Button>
              <Button
                onClick={() =>
                  (window.location.href = "https://www.alceeavocats.com/")
                }
              >
                Revenir sur le site Alcee avocats
              </Button>
            </Flexbetween>
          </Flexbetween>
        </Box>
      </Flexbetween>
    </Box>
  );
};

export default CheckoutSuccess;
