import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Alert } from "@mui/material";

export default function AddressSearch({
  defaultValue,
  label = "Renseigner l'adresse",
  onSelectionChange = () => {},
}) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [error, setError] = React.useState(null);

  const fetchAdress = React.useMemo(
    () =>
      debounce((request, callback) => {
        fetch(`https://api-adresse.data.gouv.fr/search/?q=${request.input}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Error: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => callback(data.features || []))
          .catch((error) => {
            setError(error.message); // Set the error message
            callback([]);
          });
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    setError(null);

    if (inputValue.length < 4) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchAdress({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchAdress]);

  return (
    <Box sx={{ width: "100%", gap: "10px" }}>
      {error && (
        <Alert severity="error">{error}</Alert> // Display error if exists
      )}

      <Autocomplete
        id="address-search"
        fullWidth
        freeSolo={inputValue.length < 3}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.properties?.label
        }
        filterOptions={(x) => x}
        options={options}
        includeInputInList
        filterSelectedOptions
        value={value || defaultValue}
        noOptionsText="Adresse introuvable"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onSelectionChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} fullWidth />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          const matches = option?.properties?.label;

          const parts = parse(
            matches,
            [[0, matches.length]] // Mocking the matches
          );
          return (
            <li key={key} {...optionProps}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option?.properties?.context}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
}
